// хак для хромовского автозаполнения полей
// fields - массив id полей
export const detectAutofill = (fields) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const autoFilled = fields.every((element) => {
        const el = document.getElementById(element);
        return (
          window.getComputedStyle(el, null).getPropertyValue("appearance") ===
          "menulist-button"
        );
      });
      resolve(autoFilled);
    }, 700);
  });
};
