<template>
  <v-row justify="center" class="mt-10">
    <v-col cols="12" sm="10" md="5" lg="4">
      <v-card class="elevation-12">
        <v-form @submit="submit">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("ui_auth") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-text-field
              id="username-field"
              v-model.trim="form.username"
              name="username"
              prepend-icon="mdi-account"
              label="Аккаунт"
              placeholder="Введите адрес почты"
              required
            ></v-text-field>
            <v-text-field
              id="password-field"
              v-model.trim="form.password"
              name="password"
              prepend-icon="mdi-lock"
              type="password"
              label="Пароль"
              placeholder="Введите пароль"
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" :to="{ name: 'registration' }">{{
              $t("ui_not_reg_yet")
            }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="disabled"
              @click.prevent="submit"
              >{{ $t("ui_login") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { detectAutofill } from "@/utils/autofill";

export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      loading: false,
      autoFilled: false,
    };
  },
  computed: {
    disabled() {
      if (this.autoFilled) return false;
      return this.loading || !this.form.username || !this.form.password;
    },
  },
  methods: {
    ...mapActions("core$common", ["login"]),
    submit() {
      if (this.disabled) return;

      this.loading = true;

      const formData = new FormData();
      formData.append("username", this.form.username);
      formData.append("password", this.form.password);

      this.login({
        name: "login",
        payload: formData,
        showAlert: true,
      })
        .then(() => {
          this.$router.push({ name: "home" }).catch(() => null);
        })
        .finally(this.clear);
    },
    clear() {
      this.form.username = "";
      this.form.password = "";
      this.loading = false;
    },
  },
  mounted() {
    detectAutofill(["username-field", "password-field"]).then((autoFilled) => {
      this.autoFilled = autoFilled;
    });
  },
  watch: {
    form: {
      deep: true,
      handler() {
        this.autoFilled = false;
      },
    },
  },
};
</script>
